import React from "react";

import Menu from "./Menu";

import "@styles/SideBarMobile.css";

const SideBarMobile = () => {
        return (
                <div className="sideBarMobile">
                        <Menu />
                </div>
        );
}

export default SideBarMobile;
